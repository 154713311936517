import React from "react"
import theme from './home.module.scss'
import Card from 'components/ui/Card'
import MenuToolbar from 'components/MenuToolbar'
import Layout from "components/layout"

import COMMUNITY_CHAT from 'images/community_chat.png'
import IOT from 'images/iot.png'
import MOBILE from 'images/mobile.png'
import WEB from 'images/web.png'
import NETWORK from 'images/network.png'
import cn from 'classnames'

const About = () => (
  <>
    <MenuToolbar />
    <Layout>
      <div className={theme.HeaderSection}>
        <div className={cn(theme.Col_Centered, theme.ContentTitle)}>
          <h3>Build With Confidence</h3>
          <p>
            Learn from professional developers and product specialist
          </p>
        </div>

        <div className={theme.Home_CardRow}>
          <Card
            className={theme.Home_Card}
            imgUrl={WEB}
            title="Web"
            description="Explore popular web frameworks like Angular and React. "
          />

          <Card
            className={theme.Home_Card}
            imgUrl={MOBILE}
            title="Mobile"
            description="Engage users with native applications for mobile"
          />

          <Card
            className={theme.Home_Card}
            imgUrl={IOT}
            title="IoT"
            description="Create solutions for embedded & smart devices"
          />
        </div>

        <div className={cn(theme.Col_Centered, theme.ContentTitle)}>
          <h3>A Creative HQ For You And Your Team</h3>
          <p>
            Resources to help you connect and build with other tech creatives
          </p>
        </div>

        <div style={{ maxWidth: '700px', margin: '0 auto' }} className={theme.Home_CardRow}>
          <Card
            className={theme.Home_Card}
            imgUrl={COMMUNITY_CHAT}
            title="Discuss"
            description="Join our public chat of fellow creatives to discuss and share ideas"
          />

          <Card
            className={theme.Home_Card}
            imgUrl={NETWORK}
            title="Jobs"
            description="Find your next opportunity and work with awesome teams"
          />
        </div>
      </div>
    </Layout>
  </>
)

export default About